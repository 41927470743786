// clipboard.js
//
// JS-based `Copy` buttons for code snippets.

.bd-clipboard,
.bd-edit {
    position: relative;
    display: none;
    float: right;

    + .highlight {
        margin-top: 0;
    }

    @include media-breakpoint-up(md) {
        display: block;
    }
}

.btn-clipboard,
.btn-edit {
    display: block;
    padding: .5em;
    line-height: 1;
    color: var(--bs-body-color);
    background-color: var(--bd-pre-bg);
    border: 0;
    @include border-radius(.25rem);

    &:hover {
        color: var(--bs-link-hover-color);
    }

    &:focus {
        z-index: 3;
    }
}

.btn-clipboard {
    position: relative;
    z-index: 2;
    margin-top: 1.25rem;
    margin-right: .75rem;
}
