//  code-snippet

@import "clipboard-js";

$bd-gutter-x: 3rem;

:root,
[data-bs-theme="light"] {
    --bd-pre-bg: var(--bs-tertiary-bg);
}

@include color-mode(dark, true) {
    --bd-pre-bg: #{adjust-color($gray-900, $lightness: -2.5%)}; // stylelint-disable-line scss/at-function-named-arguments
}

.highlight {
    position: relative;
    padding: .75rem ($bd-gutter-x * .5);
    background-color: var(--bd-pre-bg);

    @include media-breakpoint-up(md) {
        padding: .75rem 1.25rem;
        @include border-radius(calc(var(--bs-border-radius) - 1px));
    }

    @include media-breakpoint-up(lg) {
        pre {
            margin-right: 1.875rem;
        }
    }

    pre {
        padding: .25rem 0 .875rem;
        margin-top: .8125rem;
        margin-bottom: 0;
        overflow: overlay;
        white-space: pre;
        background-color: transparent;
        border: 0;
    }

    pre code {
        @include font-size(inherit);
        color: var(--bs-body-color); // Effectively the base text color
        word-wrap: normal;
    }
}

.bd-example-snippet .highlight pre {
    margin-right: 0;
}

.highlight-toolbar {
    background-color: var(--bd-pre-bg);

    + .highlight {
        @include border-top-radius(0);
    }
}

.bd-file-ref {
    .highlight-toolbar {
        @include media-breakpoint-up(md) {
            @include border-top-radius(calc(var(--bs-border-radius) - 1px));
        }
    }
}

.bd-content .bd-code-snippet {
    margin-bottom: 1rem;
}


.bd-code-snippet {
    //margin: 0 ($bd-gutter-x * -.5) 1rem;
    margin: 0 0 1rem;
    border: solid var(--bs-border-color);
    border-width: 1px 0;

    @include media-breakpoint-up(md) {
        //border-width: 1px;
        margin-right: 0;
        margin-left: 0;
        @include border-radius(var(--bs-border-radius));
    }

    @include media-breakpoint-up(sm) {
        border-width: 1px;
    }
}
